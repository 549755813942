import * as React from "react";
import { Flex, Box } from "reflexbox";
import Layout from "../components/layout";
import { Center, H1, H2 } from "../components/typography/styled/typography";
import SEO from "../components/seo";
import { Container } from "../components/layout/container";
import { Ornament } from "../components/layout/ornament";
import { Lang } from "../components/tr";

const PaintingMediums = ({ pageContext: { lang = Lang.cs }, location }) => (
  <Layout lang={lang} location={location}>
    <SEO lang={lang} title="Painting mediums" />
    <Container>
      <Flex flexWrap={"wrap"} justifyContent={"center"}>
        <Box width={[1, 1, 3 / 4, 2 / 3, 2 / 3]} py={4} px={[3, 3, 3, 0, 0]}>
          <Ornament />
          <Center>
            <H1>Rozdíly mezi technikami malby</H1>
          </Center>
          <H2>Olejomalba</H2>
          <p>
            - ukázka plátna/struktury - ukázka barvy na plátně (100% bavlněné
            plátno {">"}300g/m^2 - lepenka nebo rám - rám z masivní borovice -
            olejové barvy jsou vysoce světlostálé (Používám olejové barvy značky
            Umton, Master Class nebo Van Gog) - složitější na výrobu - některé
            části je potřeba nechat zaschnout než se zase bude nanášet další
            barva. Schnutí může trvat v závislosti na požadovaném výsledku 1-3
            dny. - nanáší se závěrečný lak, který malbu chrání před prachem a
            nečistotami, zvýrazní barvy. Malba vydrží desítky let bez zásahu a
            neutrpí na kvalitě. - olejomalba se nedává za sklo, aby barvy mohly
            dýchat a “pracovat”
          </p>

          <H2>Akvarelové (vodové) barvy</H2>
          <p>
            - ukázka papírů - - ukázka barvy na papíře - používám akvarelové
            barvy White Nights - jednodušší na výrobu - - technika lze
            kombinovat i s kresbou pastelek nebo pastelů pro detailnější konečný
            výsledek - akvarelový obrázek je lepší dát za sklo nebo fólii, aby
            se obraz nezašpinil nebo na něj neulpěly nečistoty.
          </p>
        </Box>
      </Flex>
    </Container>
  </Layout>
);

export default PaintingMediums;
// export const query = graphql`
//   query getAboutCzData {
//     file(relativePath: { eq: "profile.png" }) {
//       childImageSharp {
//         fixed(width: 500, height: 666, quality: 90) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `
